export default {
  "wrapper": "cZZ input-field mb-4",
  "error": "cZR",
  "inactive": "cZQ",
  "hidden-value": "cZe",
  "percentage-sign": "cZB",
  "black": "cZE",
  "input-field": "cZd",
  "disabled": "cZI"
};
