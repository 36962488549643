export default {
  "guests-loading": "cRu",
  "header": "cQS",
  "body": "cQc",
  "members": "cQq",
  "title": "cQZ caption-bold",
  "placeholder-container": "cQR",
  "icon": "cQQ",
  "details": "cQe"
};
