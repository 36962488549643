/* import __COLOCATED_TEMPLATE__ from './products-and-services.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import type { BasketItem } from '@repo/domain-kit/payment-links';
import type IntlService from 'ember-intl/services/intl';

import type { FlowStepArgs } from 'qonto/components/flow-in-flow';
import { ProductsAndServices } from 'qonto/react/payment-links/components/flows/creation/products-and-services';

interface PaymentLinksCreationProductsAndServicesSignature {
  Args: FlowStepArgs<{
    selectedItems: BasketItem[];
  }>;
}

export default class PaymentLinksCreationProductsAndServices extends Component<PaymentLinksCreationProductsAndServicesSignature> {
  productsAndServices = ProductsAndServices;

  @service intl!: IntlService;

  get breadcrumb() {
    return [
      { title: this.intl.t('payment-link.create.step-1.navigation'), active: true },
      {
        title: this.intl.t('payment-link.create.step-2.navigation'),
        active: false,
        disabled: true,
      },
      {
        title: this.intl.t('payment-link.create.step-3.navigation'),
        active: false,
        disabled: true,
      },
    ];
  }

  get selectedItems() {
    return this.args.context.selectedItems;
  }

  @action
  handleAbort() {
    this.args.abort();
  }

  @action
  handleSelectItem(product: BasketItem) {
    this.args.context.selectedItems = [...this.args.context.selectedItems, product];
  }

  @action
  handleRemoveItem(product: BasketItem) {
    this.args.context.selectedItems = this.args.context.selectedItems.filter(
      p => p.id !== product.id
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PaymentLinks::Creation::ProductsAndServices': typeof PaymentLinksCreationProductsAndServices;
  }
}
