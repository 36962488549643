/* import __COLOCATED_TEMPLATE__ from './payment-details.hbs'; */
import EmberObject, { action } from '@ember/object';
import { getOwner } from '@ember/owner';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
// @ts-expect-error
import { cached, tracked } from '@glimmer/tracking';

import { parseDate } from '@internationalized/date';
import {
  AmountField,
  Checkbox,
  ChoiceChip,
  DatePicker,
  Disclaimer,
  TextField,
} from '@repo/design-system-kit';
import { PaymentDetailSkeleton } from '@repo/domain-kit/transfers';
import dayjs from 'dayjs';
import { dropTask, task } from 'ember-concurrency';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import {
  PAY_LATER_FLOW_ORIGIN,
  PAY_LATER_VALIDATION_ERROR_TYPES,
  PAY_LATER_VALIDATION_ERRORS,
} from 'qonto/constants/financing';
// @ts-expect-error
import { SUPPLIER_STATUSES } from 'qonto/constants/supplier';
import { TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
import type SupplierModel from 'qonto/models/supplier';
import type TransferModel from 'qonto/models/transfer';
import { PayableAmountTooltip } from 'qonto/react/components/supplier-invoices/payable-amount-tooltip';
import { PaymentDetailsCard } from 'qonto/react/components/transfers/sepa/review-payment-details/payment-details-card';
import type { Attachment } from 'qonto/react/models/transaction';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
// @ts-expect-error
import { getLabelsToUpdate } from 'qonto/utils/persist-labels';
// @ts-expect-error
import scrollIntoView from 'qonto/utils/scroll-into-view';
import { calculateVatAmount } from 'qonto/utils/transfers';
// @ts-expect-error
import createPayLaterTransferValidations from 'qonto/validations/pay-later-transfer';

const PAY_LATER_BLOCKING_ERRORS = [
  PAY_LATER_VALIDATION_ERRORS.PREVENT_FF,
  PAY_LATER_VALIDATION_ERRORS.AMOUNT_LESS_THAN_THRESHOLD,
  PAY_LATER_VALIDATION_ERRORS.AMOUNT_MORE_THAN_AVAILABLE_CREDIT,
  PAY_LATER_VALIDATION_ERRORS.INVALID_DUE_DATE,
  PAY_LATER_VALIDATION_ERRORS.INVALID_ISSUE_DATE,
];

interface Amount {
  value: string;
  currency: string;
}

interface FlowsTransfersSepaNewPaymentDetailsSignature {
  // The arguments accepted by the component
  Args: {
    transitionToNext: () => void;
    context: {
      sepaTransfer: TransferModel;
      invoice: {
        id: string;
        dueDate: string;
        supplierSnapshot: SupplierModel;
        relatedInvoices: [];
        totalAmountCreditNotes: Amount;
        payableAmount: Amount;
        totalAmount: Amount;
        frenchEInvoicing: boolean;
        isGermanEInvoice: boolean;
        isItalianEInvoice: boolean;
        attachment: Attachment;
        isEinvoice: boolean;
      };
      origin: string;
      validationError: string[] | { type: string; errors: any } | null;
      didValidateFinancing: boolean;
      payLaterErrors: string[] | { type: string; errors: any } | null;
      isPayLaterEligible: boolean;
      didInstallmentsFail: boolean;
      isInstantFallback: boolean;
      isDedicatedFlow: boolean;
    };
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PaymentDetailsComponent extends Component<FlowsTransfersSepaNewPaymentDetailsSignature> {
  paymentDetailsCard = PaymentDetailsCard;
  datePicker = DatePicker;
  choiceChip = ChoiceChip;
  checkbox = Checkbox;
  textField = TextField;
  amountField = AmountField;
  payableAmountTooltip = PayableAmountTooltip;
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;
  paymentDetailSkeleton = PaymentDetailSkeleton;

  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare store: Services['store'];
  @service declare beneficiariesManager: Services['beneficiariesManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare sentry: Services['sentry'];
  @service declare financing: Services['financing'];
  @service declare segment: Services['segment'];
  @service declare modals: Services['modals'];

  @tracked today = dayjs().format(DATE_PICKER_FIELD_FORMAT);
  @tracked isEditing = false;
  @tracked isAmountEdited = false;
  @tracked isValidationEnabled = false;

  @cached
  get payLaterDataModel() {
    if (this.args.context.isDedicatedFlow) {
      return this.#createPayLaterDataModel();
    }
  }

  constructor() {
    // @ts-expect-error
    super(...arguments);
    this.fetchSuppliersTask.perform().catch(ignoreCancelation);
  }

  get supplierIbanDisclaimer() {
    let { name, iban } = this.args.context.invoice.supplierSnapshot;

    if (!name || iban) return false;

    return this.isValidationEnabled ? 'error' : 'warning';
  }

  get showSupplierMissingError() {
    let { name } = this.args.context.invoice.supplierSnapshot;

    return !name && this.isValidationEnabled;
  }

  get isLoading() {
    return this.fetchSuppliersTask.isRunning;
  }

  get originForErrorTrackingEvent() {
    let { origin } = this.args.context;

    if (origin === TRANSFER_FLOW_ORIGIN.PAY_LATER_COCKPIT) {
      return PAY_LATER_FLOW_ORIGIN.COCKPIT;
    }
    return origin === TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES;
  }

  get scheduledDate() {
    return this.args.context.sepaTransfer.scheduledDate
      ? parseDate(this.args.context.sepaTransfer.scheduledDate)
      : null;
  }

  get minDate() {
    let minDate = dayjs().startOf('day').format(DATE_PICKER_FIELD_FORMAT).toString();
    return parseDate(minDate);
  }

  get showDateChoiceChips() {
    let invoiceDueDate = this.args.context.invoice.dueDate;
    if (!invoiceDueDate) return false;

    let invoiceDueDateToday = dayjs(invoiceDueDate).format('YYYY-MM-DD');

    return dayjs(invoiceDueDateToday).diff(dayjs(this.today)) >= 0;
  }

  get isPaymentDateToday() {
    return this.today === this.args.context.sepaTransfer.scheduledDate;
  }

  get isPaymentDateInvoiceDate() {
    return this.args.context.sepaTransfer.scheduledDate === this.args.context.invoice.dueDate;
  }

  get isBookkeepingExpanded() {
    let hasCustomLabels =
      this.abilities.can('access custom-label') && this.abilities.can('read custom-label');

    //TODO: also add the check if previous transfer had vat rate selected
    return hasCustomLabels;
  }

  get hasCreditNoteLinked() {
    return this.args.context.invoice.relatedInvoices?.length;
  }

  get showPayableAmountDisclaimer() {
    return !this.isAmountEdited && this.hasCreditNoteLinked;
  }

  get totalCreditNotesAmount() {
    let { totalAmountCreditNotes } = this.args.context.invoice;

    return totalAmountCreditNotes
      ? // @ts-expect-error
        this.intl.formatMoney(totalAmountCreditNotes?.value, {
          currency: totalAmountCreditNotes?.currency,
          signus: '-',
        })
      : 0;
  }

  get creditNotesCount() {
    return this.args.context.invoice.relatedInvoices?.length || 0;
  }

  get payableAmount() {
    let { payableAmount } = this.args.context?.invoice || {};
    // @ts-expect-error
    return this.intl.formatMoney(payableAmount?.value, { currency: payableAmount?.currency });
  }

  get totalAmount() {
    let { totalAmount } = this.args.context.invoice;
    // @ts-expect-error
    return this.intl.formatMoney(totalAmount?.value, { currency: totalAmount?.currency });
  }

  get isEInvoice() {
    let {
      frenchEInvoicing = false,
      isGermanEInvoice = false,
      isItalianEInvoice = false,
    } = this.args.context.invoice;

    return frenchEInvoicing || isGermanEInvoice || isItalianEInvoice;
  }

  get shouldShowExpanded() {
    let origin = this.args.context.origin;
    return origin === TRANSFER_FLOW_ORIGIN.PAY_BY_INVOICE || this.args.context.isDedicatedFlow;
  }

  get payLaterTransferFlowOrigin() {
    if (this.args.context.origin === TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES) {
      return TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES;
    }

    return TRANSFER_FLOW_ORIGIN.PAY_BY_INVOICE;
  }

  @action
  handleLabelSelect(labelList: [], label: {}) {
    let { sepaTransfer } = this.args.context;
    sepaTransfer.labels = getLabelsToUpdate(sepaTransfer.labels, labelList, label);
    return sepaTransfer;
  }

  @action
  setScheduledDate(date: Date) {
    this.args.context.sepaTransfer.scheduledDate = date?.toString();
  }

  @action
  onDateChipClick(chip: string) {
    if (chip === 'today') {
      this.args.context.sepaTransfer.scheduledDate = this.today;
    } else if (chip === 'invoice-date') {
      this.args.context.sepaTransfer.scheduledDate = this.args.context.invoice.dueDate;
    }
  }

  @action
  onEditClick() {
    let { invoice } = this.args.context;
    this.isEditing = true;
    this.segment.track('transfer-sepa_edit_clicked', {
      flow: 'byinvoice',
      origin: TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES,
      is_einvoice: invoice.isEinvoice,
    });
  }

  @action
  onNotifyByEmailChange(value: boolean) {
    if (value) {
      this.args.context.sepaTransfer.email = this.args.context.invoice.supplierSnapshot.email;
    } else {
      this.args.context.sepaTransfer.email = '';
    }

    this.args.context.sepaTransfer.notifyByEmail = value;
  }

  // Disabled rule because using .then in `handleSaveVatAmount`
  // @ts-expect-error
  // eslint-disable-next-line require-await
  async handleSelectVat(model, value) {
    model.setProperties(value);
    return model;
  }

  @action
  onAmountChange(amount: number) {
    this.isAmountEdited = true;
    this.args.context.sepaTransfer.amount = amount;
    this._updateVatAmount();
  }

  @action
  onReferenceChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.args.context.sepaTransfer.reference = event.currentTarget?.value;
  }

  @action
  updateSupplier(prop: string, value: string) {
    // @ts-expect-error
    this.args.context.invoice[prop] = value;
  }

  _updateVatAmount() {
    let { sepaTransfer } = this.args.context;
    let { vatRate, amount } = sepaTransfer;

    if (vatRate > 0) {
      sepaTransfer.vatAmount = Number(calculateVatAmount(amount, vatRate));
    }
  }

  fetchSuppliersTask = dropTask(async (search = '') => {
    return await this.store.query('supplier', {
      search,
      filter: { status: SUPPLIER_STATUSES.UNARCHIVED },
    });
  });

  onClickContinueTask = dropTask(async () => {
    let { context, transitionToNext } = this.args;
    let { invoice: supplierInvoice, sepaTransfer } = context;
    let { supplierSnapshot: supplier } = supplierInvoice;

    if (supplier.name && supplier.iban) {
      let beneficiary = this.beneficiariesManager.createSepaBeneficiary(
        this.organizationManager.organization,
        {
          iban: supplier.iban,
          name: supplier.name,
          vatRate: supplier.defaultVatRate,
        }
      );

      try {
        await beneficiary.save();
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        return this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
      } finally {
        sepaTransfer.set('beneficiary', beneficiary);
      }
    }

    if (!sepaTransfer.usePayByInvoiceValidations) {
      sepaTransfer.usePayByInvoiceValidations = true;
    }
    await sepaTransfer.validate();

    let { amount, bankAccount, reference, scheduledDate, email } = sepaTransfer.validations.attrs;

    if (
      bankAccount.isInvalid ||
      reference.isInvalid ||
      scheduledDate.isInvalid ||
      (sepaTransfer.notifyByEmail && email.isInvalid) ||
      !supplier.name ||
      !supplier.iban
    ) {
      this.isEditing = true;
      this.isValidationEnabled = true;
      this._scrollToErrorField();
      return;
    }

    if (context.isDedicatedFlow) {
      let hasBeneficiary = sepaTransfer.get('beneficiary').content;

      let isFormValid = await this.validateInlineForm();

      if (!isFormValid || !hasBeneficiary) {
        this._scrollToErrorField();
        return;
      }
      try {
        context.didValidateFinancing = false;
        await this.handleValidationTask.perform();
        context.didValidateFinancing = true;

        this.segment.track('pay-later-dedicated-flow_errors_detected', {
          origin: this.originForErrorTrackingEvent,
          error: context.validationError
            ? Array.isArray(context.validationError)
              ? context.validationError
              : context.validationError.errors
            : ['no_error'],
        });

        await this.handlePayLaterInstallments.perform();

        if (context.didInstallmentsFail) {
          // In Pay later transfer flow, user can only continue if we have the installments data
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
          return;
        }

        context.isInstantFallback = false;
      } catch (error) {
        // @ts-expect-error
        let findAttachmentIdError = error.errors?.find(
          ({ code }: { code: string }) => code === 'attachment_id_not_found'
        );
        // @ts-expect-error
        if (error.status === 404 && findAttachmentIdError) {
          context.validationError = {
            type: PAY_LATER_VALIDATION_ERROR_TYPES.ATTACHMENT_ID,
            errors: [findAttachmentIdError.code],
          };
        } else {
          return this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
        }
      }
    }

    if (context.isPayLaterEligible && !context.isDedicatedFlow) {
      try {
        context.didValidateFinancing = false;
        await this.validateFinancingTask.perform();
        context.didValidateFinancing = true;
      } catch (error) {
        // @ts-expect-error
        if (ErrorInfo.for(error).shouldSendToSentry && error.status !== 404) {
          this.sentry.captureException(error);
        }
      }
    }

    this.isValidationEnabled = true;

    if (amount.isInvalid) {
      this.isEditing = true;
      this.handleLowBalanceTask.perform().catch(ignoreCancelation);

      this._scrollToErrorField();
      return;
    }

    this._trackContinueCTA();

    transitionToNext();
  });

  async validateInlineForm() {
    let { validations } = await this.payLaterDataModel.validate();

    this.isValidationEnabled = true;

    return validations.isValid;
  }

  handleLowBalanceTask = dropTask(async () => {
    let { context } = this.args;
    let { sepaTransfer } = context;

    let isLowBalanceScenario =
      Number(sepaTransfer.amount) > Number(sepaTransfer.bankAccount.authorizedBalance);

    if (!context.isPayLaterEligible || !isLowBalanceScenario) return;

    if (!context.didValidateFinancing) {
      return this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    }

    // @ts-expect-error
    if (!PAY_LATER_BLOCKING_ERRORS.some(error => context.payLaterErrors?.includes(error))) {
      await this.modals.open('transfers/sepa/pay-later/low-balance-modal', {
        confirmTask: this.redirectToPayLaterFlowTask,
      });
    }
  });

  redirectToPayLaterFlowTask = dropTask(async close => {
    let { context } = this.args;

    await close();

    // @ts-expect-error
    this.args.transitionToFlow({
      flowName: 'pay-later-transfer',
      stepId: 'payment-details',
      queryParams: {
        origin: this.payLaterTransferFlowOrigin,
        invoiceId: context.invoice.id,
        transferId: context.sepaTransfer.idempotencyKey,
      },
    });
  });

  validateFinancingTask = dropTask(async () => {
    let { context } = this.args;
    let { sepaTransfer, invoice } = context;

    let amount = {
      value: sepaTransfer.amount,
      currency: sepaTransfer.amountCurrency,
    };
    let attachmentId = invoice.attachment.id;
    let beneficiary = sepaTransfer.belongsTo('beneficiary').value();
    let iban = beneficiary.iban;
    let beneficiaryName = beneficiary.name;
    let selectedAccountIban = sepaTransfer.bankAccount.iban;
    let scheduledDate = sepaTransfer.scheduledDate;
    let operationType = sepaTransfer.operationType;

    context.payLaterErrors = await this.financing.validatePayLaterTransfer({
      amount,
      attachmentId,
      iban,
      beneficiaryName,
      selectedAccountIban,
      scheduledDate,
      operationType,
    });

    this.segment.track('pay-later_transfer-flow_errors_detected', {
      error: context.payLaterErrors || ['no_error'],
    });
  });

  handleValidationTask = task(async () => {
    let { sepaTransfer, invoice } = this.args.context;

    let amount = {
      value: sepaTransfer.amount,
      currency: sepaTransfer.amountCurrency,
    };
    // @ts-expect-error
    let attachmentId = invoice.attachment.get('id');
    let iban = sepaTransfer.beneficiary.get('iban');
    let beneficiaryName = sepaTransfer.beneficiary.get('name');

    this.args.context.validationError = await this.financing.validatePayLaterTransfer({
      amount,
      attachmentId,
      iban,
      beneficiaryName,
      groupErrors: true,
    });
  });

  handlePayLaterInstallments = dropTask(async () => {
    let { context } = this.args;

    let { invoice, payLaterErrors, isPayLaterEligible, didValidateFinancing, sepaTransfer } =
      context;
    let { amount, amountCurrency } = sepaTransfer;

    context.didInstallmentsFail = false;

    if (!invoice || !didValidateFinancing || !isPayLaterEligible || payLaterErrors) {
      return;
    }

    try {
      // @ts-expect-error
      context.payLaterInstallments = await this.financing.getPayLaterTransferInstallments({
        value: amount,
        currency: amountCurrency,
      });
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      context.didInstallmentsFail = true;
    }
  });

  _scrollToErrorField() {
    next(() => scrollIntoView('[slot="errorMessage]'));
  }

  _trackContinueCTA() {
    let { invoice, origin, sepaTransfer } = this.args.context;
    let { attachments, amount, reference, beneficiary } = sepaTransfer;

    let hasAmount = Boolean(amount);
    let hasIban = Boolean(beneficiary?.get('iban'));
    let hasReference = Boolean(reference);
    let hasAttachments = Boolean(attachments.length);

    this.segment.track('transfer-sepa_edit_saved', {
      flow: 'byinvoice',
      origin,
      is_einvoice: invoice.isEinvoice,
      amount: hasAmount,
      iban: hasIban,
      reference: hasReference,
      attachments: hasAttachments,
    });
  }

  #createPayLaterDataModel() {
    let { amount, reference } = this.args.context.sepaTransfer;
    let PayLaterValidationModel = this.#createPayLaterValidationModel();

    // @ts-expect-error
    class PayLaterDataModel extends EmberObject.extend(PayLaterValidationModel) {
      // @ts-expect-error
      @service intl;
      // @ts-expect-error
      @service localeManager;

      // @ts-expect-error
      @tracked amount;
      // @ts-expect-error
      @tracked reference;
    }

    // @ts-expect-error
    return PayLaterDataModel.create(getOwner(this).ownerInjection(), { amount, reference });
  }

  #createPayLaterValidationModel() {
    // @ts-expect-error
    let { minTransferAmount, availableCreditAmount } = this.args.context;

    return createPayLaterTransferValidations(
      minTransferAmount,
      availableCreditAmount,
      this.#getValidationMessage.bind(this)
    );
  }

  // @ts-expect-error
  #getValidationMessage(minTransferAmount, availableCreditAmount) {
    // @ts-expect-error
    return (key, value) => {
      if (value < minTransferAmount.value) {
        return this.#getMinimumAmountValidationMessage(minTransferAmount);
      } else if (value > availableCreditAmount.value) {
        return this.#getAvailableCreditValidationMessage(availableCreditAmount);
      }
    };
  }

  // @ts-expect-error
  #getMinimumAmountValidationMessage(minTransferAmount) {
    let formattedAmount = this.intl.formatNumber(minTransferAmount.value, {
      style: 'currency',
      currency: minTransferAmount.currency,
      minimumFractionDigits: 2,
    });

    return this.intl.t('validations.errors.pay-later.minimum-amount', {
      amount: formattedAmount,
    });
  }

  // @ts-expect-error
  #getAvailableCreditValidationMessage(availableCreditAmount) {
    let formattedAmount = this.intl.formatNumber(availableCreditAmount.value, {
      style: 'currency',
      currency: availableCreditAmount.currency,
      minimumFractionDigits: 2,
    });

    return this.intl.t('validations.errors.pay-later.available-credit', {
      amount: formattedAmount,
    });
  }
}
