/* import __COLOCATED_TEMPLATE__ from './choose-card-digital.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_LEVELS } from 'qonto/constants/cards';
import { ChooseCardDigital } from 'qonto/react/cards/components/flows/choose-card-digital';

interface FlowsCardsChooseVirtualCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsChooseVirtualCard extends Component<FlowsCardsChooseVirtualCardSignature> {
  chooseCardDigital = ChooseCardDigital;

  @service declare cardsManager: Services['cardsManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare abilities: Services['abilities'];
  @service declare upsellManager: Services['upsellManager'];

  CARD_LEVELS = CARD_LEVELS;

  get currentPricePlanRecurrence() {
    // @ts-expect-error
    return this.args.context.currentSubscription?.recurrence;
  }

  onUpsellSuccess = ({ recurrence, addonCode }: { recurrence: string; addonCode: string }) => {
    // @ts-expect-error
    this.args.context.recurrence = recurrence;
    // @ts-expect-error
    this.args.context.addonCode = addonCode;
    // @ts-expect-error
    this.args.pushFlow('addon-change', 'addons');
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::ChooseCardDigital': typeof FlowsCardsChooseVirtualCard;
  }
}
