export default {
  "separator": "cci",
  "vat-accounting": "ccA",
  "labels": "ccY",
  "label-title": "cch",
  "amount-and-reference-wrapper": "ccf",
  "amount-wrapper": "ccK",
  "payable-amount-tooltip": "ccG",
  "sticky-panel": "ccr",
  "with-iban-disclaimer": "ccb"
};
