/* import __COLOCATED_TEMPLATE__ from './payment-methods.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import type { BasketItem } from '@repo/domain-kit/payment-links';
import type IntlService from 'ember-intl/services/intl';

import type { FlowStepArgs } from 'qonto/components/flow-in-flow';
import type PaymentLinkMethodModel from 'qonto/models/payment-link-method';
import { PaymentMethods } from 'qonto/react/payment-links/components/flows/creation/payment-methods';

interface PaymentLinksCreationPaymentMethodsSignature {
  Args: FlowStepArgs<{
    selectedMethods: PaymentLinkMethodModel['method'][];
    selectedItems: BasketItem[];
  }>;
}

export default class PaymentLinksCreationPaymentMethods extends Component<PaymentLinksCreationPaymentMethodsSignature> {
  paymentMethods = PaymentMethods;

  @service intl!: IntlService;

  get breadcrumb() {
    let canGoForwardToSettings = this.args.context.selectedMethods.length > 0;
    return [
      {
        title: this.intl.t('payment-link.create.step-1.navigation'),
        active: false,
      },
      {
        title: this.intl.t('payment-link.create.step-2.navigation'),
        active: true,
      },
      {
        title: this.intl.t('payment-link.create.step-3.navigation'),
        active: false,
        disabled: !canGoForwardToSettings,
        onClick: () => {
          if (canGoForwardToSettings) {
            this.args.transitionToNext();
          }
        },
      },
    ];
  }

  @action
  handleSelectMethod(method: PaymentLinkMethodModel['method']) {
    this.args.context.selectedMethods = [...this.args.context.selectedMethods, method];
  }

  @action
  handleRemoveMethod(method: PaymentLinkMethodModel['method']) {
    this.args.context.selectedMethods = this.args.context.selectedMethods.filter(m => m !== method);
  }

  @action
  handleAbort() {
    this.args.abort();
  }

  @action
  handleContinue() {
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PaymentLinks::Creation::PaymentMethods': typeof PaymentLinksCreationPaymentMethods;
  }
}
