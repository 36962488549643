export default {
  "container": "cZf",
  "container--collapsed": "cZK",
  "spin": "cZG",
  "cta-tooltip": "cZr",
  "cta--collapsed": "cZb",
  "cta--expanded": "cZy",
  "collapsed-svg-icon": "cZU",
  "expanded-svg-icon": "cZj",
  "generate-logo-cta": "cZW",
  "badge": "cZu",
  "scaleDown": "cRS",
  "fadeIn": "cRc",
  "textRiseButton": "cRq",
  "cta-description": "cRZ",
  "textRiseDescription": "cRR"
};
