export default {
  "color-option": "cZz",
  "color-dot": "cZa",
  "any": "cZH",
  "red": "cZO",
  "orange": "cZm",
  "yellow": "cZp",
  "green": "cZx",
  "blue": "cZw",
  "purple": "cZN",
  "pink": "cZv",
  "black": "cZo",
  "grey": "cZn",
  "brown": "cZi",
  "color-name": "cZA"
};
