import { template as template_14f6c1823f3f40c3a944b0d3e9be4219 } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import DisclaimerBlock from '@qonto/ui-kit/components/disclaimer/block';
import TranslationWithArgs from '@qonto/ui-kit/components/translation-with-args';
import { t } from 'ember-intl';
import { variation } from 'ember-launch-darkly';
import ApprovalWorkflowForm from 'qonto/components/approval-workflow/form';
import { APPROVAL_WORKFLOW_CONDITION_FIELDS, REQUEST_TYPES } from 'qonto/constants/approval-workflow';
import { ABORT_DESTINATIONS } from 'qonto/routes/flows/setup/approval-workflows/approval-workflows';
import styles from './builder.strict-module.css';
export default class ApprovalWorkflowBuilder extends Component {
    @service
    intl;
    @service
    segment;
    @service
    organizationManager;
    @service
    abilities;
    get workflow() {
        return this.args.context.workflow;
    }
    get workflowRequestType() {
        return this.workflow?.requestType;
    }
    get title() {
        switch(this.workflowRequestType){
            case 'transfer':
                if (this.abilities.can('try-before-buy approval-workflow')) {
                    return this.intl.t('approval-workflows.form.transfer-requests.upsell-title');
                }
                return this.intl.t('approval-workflows.form.transfer-requests.title');
            case 'reimbursement':
                return this.intl.t('approval-workflows.form.reimbursement-requests.title');
            case 'supplier_invoice':
                return this.intl.t('approval-workflows.form.supplier-invoice-requests.title');
        }
    }
    get subtitle() {
        switch(this.workflowRequestType){
            case 'transfer':
                if (this.abilities.can('try-before-buy approval-workflow')) {
                    return this.intl.t('approval-workflows.form.upsell-subtitle');
                }
                return this.intl.t('approval-workflows.form.subtitle');
            case 'reimbursement':
                return this.intl.t('approval-workflows.form.subtitle');
            case 'supplier_invoice':
                return this.intl.t('approval-workflows.form.supplier-invoice-requests.subtitle');
        }
    }
    get shouldDisplayInviteMembersDisclaimer() {
        return this.args.context.approverOptions?.length === 1;
    }
    get shouldDisplayNewRequestsDisclaimer() {
        return this.workflowRequestType === 'supplier_invoice' ? Boolean(this.workflow?.rulesets.length) : true;
    }
    get isAmountConditionPrecedingOtherConditions() {
        if (!variation('feature--boolean-improve-builder-aw4si') || this.workflowRequestType !== REQUEST_TYPES.SUPPLIER_INVOICE) {
            return false;
        }
        let rulesets = this.workflow?.rulesets || [];
        let lastAmountIndex = rulesets.findLastIndex((ruleset)=>ruleset.conditions[0].field === APPROVAL_WORKFLOW_CONDITION_FIELDS.AMOUNT);
        if (lastAmountIndex === -1 || lastAmountIndex === rulesets.length - 1) {
            return false;
        }
        let hasNonAmountAfterAmount = rulesets.slice(lastAmountIndex + 1).every((ruleset)=>ruleset.conditions[0].field !== APPROVAL_WORKFLOW_CONDITION_FIELDS.AMOUNT);
        return hasNonAmountAfterAmount;
    }
    get newRequestsDisclaimerText() {
        let supplierInvoiceDisclaimer = variation('feature--boolean-improve-builder-aw4si') ? this.intl.t('approval-workflows.form.supplier-invoices.disclaimer.text') : this.intl.t('approval-workflows.form.supplier-invoices.disclaimer.text-ff');
        return this.workflowRequestType === 'supplier_invoice' ? supplierInvoiceDisclaimer : this.intl.t('approval-workflows.form.disclaimers.new-requests');
    }
    get displayApproversHint() {
        return this.workflowRequestType === 'supplier_invoice';
    }
    get payersHintMessage() {
        switch(this.workflowRequestType){
            case 'transfer':
            case 'reimbursement':
                return this.intl.t('approval-workflows.form.steps.payers-list.disclaimer-tooltip');
            case 'supplier_invoice':
                return this.intl.t('approval-workflows.form.steps.payers-list.supplier-invoices.hint-tooltip');
        }
    }
    @action
    redirectToUserAccess() {
        let { context, abort } = this.args;
        context.abortDestination = ABORT_DESTINATIONS.USER_ACCESS;
        abort();
    }
    @action
    onSave() {
        let { transitionToNext } = this.args;
        this.segment.track('approval-workflow-edit_save-button_clicked');
        transitionToNext();
    }
    static{
        template_14f6c1823f3f40c3a944b0d3e9be4219(`
    <ApprovalWorkflowForm
      class={{styles.approval-workflow-form}}
      @approverOptions={{@context.approverOptions}}
      @payerOptions={{@context.payerOptions}}
      @title={{this.title}}
      @subtitle={{this.subtitle}}
      @workflow={{@context.workflow}}
      @onSave={{this.onSave}}
      @context={{@context}}
      @displayApproversHint={{this.displayApproversHint}}
      @approversHintMessage={{t
        'approval-workflows.form.steps.approvers-list.supplier-invoices.hint-tooltip'
      }}
      @displayPayersHint={{true}}
      @payersHintMessage={{this.payersHintMessage}}
      @pushFlow={{@pushFlow}}
      @disableSubmitButton={{this.isAmountConditionPrecedingOtherConditions}}
    >
      <:disclaimers>
        {{#if this.shouldDisplayInviteMembersDisclaimer}}
          <DisclaimerBlock
            @level='warning'
            class={{styles.mt-16}}
            data-test-approval-workflow-form-invite-members-disclaimer
          >
            <TranslationWithArgs
              @key='approval-workflows.form.disclaimers.invite-body'
              @text={{t
                'approval-workflows.form.disclaimers.invite-body'
                link=(t 'approval-workflows.form.disclaimers.invite-link')
              }}
              as |varText|
            >
              <a
                href='#'
                {{on 'click' this.redirectToUserAccess}}
                data-test-approval-workflow-invite-member-link
              >
                {{varText}}
              </a>
            </TranslationWithArgs>
          </DisclaimerBlock>
        {{/if}}

        {{#if this.shouldDisplayNewRequestsDisclaimer}}
          <DisclaimerBlock
            @level='info'
            class={{styles.mt-16}}
            data-test-approval-workflow-form-disclaimer
          >
            {{this.newRequestsDisclaimerText}}
          </DisclaimerBlock>
        {{/if}}

        {{#if this.isAmountConditionPrecedingOtherConditions}}
          <DisclaimerBlock
            @level='error'
            class={{styles.mt-16}}
            data-test-approval-workflow-form-amount-condition-disclaimer
          >
            {{t 'approval-workflows.form.disclaimers.single-amount-ruleset'}}
          </DisclaimerBlock>
        {{/if}}
      </:disclaimers>
    </ApprovalWorkflowForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
