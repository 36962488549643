export default {
  "summary-description": "cqQ",
  "detail": "cqe",
  "detail-value": "cqB",
  "account-icon": "cqE",
  "color-secondary": "cqd",
  "beneficiary-info": "cqI",
  "currency": "cqt",
  "beneficiary-name": "cqM",
  "separator": "cqP",
  "instant-subtitle": "cql",
  "instant-wrapper": "cqX",
  "instant-header": "cqC",
  "instant-toggle": "cqk",
  "instant--disabled": "cqJ",
  "limit-exceeded-disclaimer": "cqs",
  "sticky-panel": "cqF",
  "total-interest": "cqg",
  "tooltip-icon": "cqD",
  "tooltip": "cqV",
  "payment-options": "cqL",
  "starting-on-value": "cqT",
  "standard-transfer-tooltip": "cqz"
};
