export default {
  "wrapper": "cRQ",
  "close-button": "cRe",
  "content-wrapper": "cRB",
  "main": "cRE",
  "main-content": "cRd",
  "disclaimer": "cRI",
  "description": "cRt",
  "mt-26": "cRM",
  "mt-62": "cRP",
  "user-prompt": "cRl",
  "user-prompt-inappropriate-warning": "cRX",
  "logo-preview": "cRC",
  "static-loader": "cRk",
  "loading-state-illustration": "cRJ",
  "sticky-panel": "cRs"
};
