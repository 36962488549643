/* import __COLOCATED_TEMPLATE__ from './upgrade-modal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { Badge, Button, LottiePlayer, type LottiePlayerProps } from '@repo/design-system-kit';

interface UpgradeModalSignature {
  // The arguments accepted by the component
  Args: {
    close: () => void;
    feature?: string;
    lottieProps: LottiePlayerProps;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class UpgradeModalComponent extends Component<UpgradeModalSignature> {
  BADGE_TYPE = BADGE_TYPE;

  lottiePlayer = LottiePlayer;
  button = Button;
  badge = Badge;

  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare upsellManager: Services['upsellManager'];
  @service declare userManager: Services['userManager'];

  @tracked isLoading = true;

  constructor() {
    // @ts-expect-error
    super(...arguments);
    this.init();
  }

  async init() {
    await this.upsellManager.getSelectedBillerPricePlan();
    this.isLoading = false;
  }

  get billerPricePlanName() {
    return this.upsellManager.billerPricePlan?.brand_name || '';
  }

  get lottieProps(): LottiePlayerProps {
    return {
      autoplay: true,
      loop: false,
      ...this.args.lottieProps, // Override defaults with passed values
    };
  }

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  get userFirstName() {
    // @ts-expect-error
    return this.userManager.currentUser?.firstName || '';
  }

  get hasAvailableFreeTrial() {
    return (
      this.upsellManager.hasAvailableFreeTrial(this.args.feature || '') || this.hasInitialTrial
    );
  }

  @action
  upsell() {
    this.upsellManager.upsell({ feature: this.args.feature });
  }

  @action
  restartUpsell() {
    this.upsellManager.upsell({ feature: this.args.feature, restart: true });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    UpgradeModal: typeof UpgradeModalComponent;
  }
}
