export default {
  "step-container": "ccy",
  "content": "ccU",
  "sticky-panel": "ccj",
  "banner": "ccW",
  "banner-content": "ccu",
  "banner-text": "cqS",
  "banner-image": "cqc",
  "banner-title": "cqq",
  "banner-description": "cqZ body-2",
  "link": "cqR"
};
